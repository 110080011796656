import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { addTag, getTags } from 'services/BoardService'

// initial State
const initialState = {
    tags: [],
    isLoading: false,
    isError: false,
    error: '',
}

// async thunks
export const fetchTags = createAsyncThunk('tags/fetchTags', async () => {
    const tags = await getTags()
    return tags
})

export const createTag = createAsyncThunk('tags/addTag', async (data) => {
    const tag = await addTag(data)
    return tag
})
const tagSlice = createSlice({
    name: 'tags',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchTags.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(fetchTags.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.tags = action.payload
            })
            .addCase(fetchTags.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
                state.tags = []
            })
            .addCase(createTag.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(createTag.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.tags.push(action.payload)
            })
            .addCase(createTag.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
    },
})
export default tagSlice.reducer
