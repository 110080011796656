import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getPassengers } from 'services/DatatableService'

const initialState = {
    passengers: [],
    tableData: {
        pageIndex: 1,
        pageSize: 10,
        total: 34,
        sort: {
            order: '',
            key: '',
        },
        query: {
            title: null,
            status: null,
        },
    },
    isLoading: false,
    isError: false,
    error: '',
}

// async thunks

// Fetch Boards  Thunk
export const fetchPassengers = createAsyncThunk(
    'passengers/fetchPassengers',
    async ({ pageIndex, pageSize }) => {
        const boards = await getPassengers(pageIndex, pageSize)
        return boards
    }
)

const datatableSlice = createSlice({
    name: 'passengers',
    initialState,
    reducers: {
        setPassenger: (state, action) => {
            state.passengers = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPassengers.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(fetchPassengers.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.passengers = action.payload
            })
            .addCase(fetchPassengers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
                state.passengers = []
            })
    },
})

export const { setPassenger, setTableData } = datatableSlice.actions
export default datatableSlice.reducer
