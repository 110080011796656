import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import board from '../../src/views/board/store/index'
import user from '../views/user/store/index'
import masterData from '../views/masterData/store/index'
import workspace from '../views/workspace/store/index'
import datatable from '../views/datatable/store/index'
import projectTask from '../views/project/tasks/store/index'
import scrumBoardList from '../views/project/ScrumBoardList/store/index'
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        board,
        user,
        workspace,
        masterData,
        datatable,
        projectTask,
        scrumBoardList,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
