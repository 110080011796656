import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    addBoard,
    getBoards,
    getWorkspaceMembers,
    removeBoard,
    updateBoard,
} from 'services/BoardService'

const initialState = {
    boards: [],
    board: {},
    workspaceMembers: [],
    isLoading: false,
    isError: false,
    error: '',
    editMode: false,
    addMode: false,
    viewBoard: false,
}

// async thunks

// Fetch Boards  Thunk
export const fetchBoards = createAsyncThunk('boards/fetchBoards', async () => {
    const boards = await getBoards()
    return boards
})
// Create Board Thunk
export const createBoard = createAsyncThunk(
    'boards/createBoard',
    async (data) => {
        const board = await addBoard(data)
        return board
    }
)
// Edit Board Thunk
export const editBoard = createAsyncThunk(
    'boards/editBoard',
    async ({ id, data }) => {
        const board = await updateBoard(id, data)
        return board
    }
)
// Delete Board Thunk
export const deleteBoard = createAsyncThunk(
    'boards/deleteBoard',
    async (id) => {
        const board = await removeBoard(id)
        return board
    }
)

// Fetch Workspace Member Thunk
export const fetchWorkspaceMembers = createAsyncThunk(
    'boards/workspaceMembers',
    async (id) => {
        const response = await getWorkspaceMembers(id)
        return response
    }
)

const boardSlice = createSlice({
    name: 'boards',
    initialState,
    reducers: {
        addBoardModeToggler: (state, action) => {
            state.addMode = action.payload
        },
        editBoardModeToggler: (state, action) => {
            state.editMode = action.payload.editMode
            state.board = action.payload.editItem
        },
        viewAllBoard: (state, action) => {
            state.viewBoard = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBoards.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(fetchBoards.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.boards = action.payload
            })
            .addCase(fetchBoards.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
                state.boards = []
            })
            .addCase(createBoard.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(createBoard.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.boards.push(action.payload)
            })
            .addCase(createBoard.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(editBoard.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(editBoard.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                const indexToUpdate = state.boards.findIndex(
                    (board) => board.id === action.payload.id
                )

                state.boards[indexToUpdate] = action.payload
            })
            .addCase(editBoard.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(deleteBoard.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(deleteBoard.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false

                state.boards = state.boards.filter(
                    (board) => board.id !== action.meta.arg
                )
            })
            .addCase(deleteBoard.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(fetchWorkspaceMembers.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchWorkspaceMembers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.workspaceMembers = action.payload
            })
            .addCase(fetchWorkspaceMembers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
                state.workspaceMembers = []
            })
    },
})

export const { editBoardModeToggler, addBoardModeToggler, viewAllBoard } =
    boardSlice.actions
export default boardSlice.reducer
