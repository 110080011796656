const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
    liveApiUrl: 'https://pms-backend.pul-group.com/api',
    liveSiteUrl: 'https://pms-backend.pul-group.com',
    // liveApiUrl: 'https://api.softonique.com',
    // liveApiUrl: 'https://restapi-wjip.onrender.com',
    localApiUrl: 'http://localhost:9000',
}

export default appConfig
