import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    apiGetScrumBoardLists, updateProjectTaskOrder,
} from 'services/ProjectService'
import {
    addListSection, addProjectPriority,
    editListSection,
    editProjectPriority,
    editSectionTask
} from "../../../../services/ProjectTasksService";

export const getBoardLists = createAsyncThunk('scrumBoardList/getBoardLists', async (data) => {
    const response = await apiGetScrumBoardLists(data)
    return response
})

export const updateTaskOrdered = createAsyncThunk('scrumBoardList/updateTaskOrdered', async ({data}) => {
    const response = await updateProjectTaskOrder(data)
    return response
})

export const createListSection = createAsyncThunk('scrumBoardList/createListSection', async ({data}) => {
    const response = await addListSection(data)
    return response
})
export const updateListSection = createAsyncThunk('scrumBoardList/updateListSection', async ({id, data}) => {
    const response = await editListSection(id, data)
    return response
})

export const updateSectionTask = createAsyncThunk('scrumBoardList/updateSectionTask', async ({id, data}) => {
    const response = await editSectionTask(id, data)
    return response
})

export const createProjectPriority = createAsyncThunk('scrumBoardList/createProjectPriority', async (data) => {
    const response = await addProjectPriority(data)
    return response
})

export const updateProjectPriority = createAsyncThunk('scrumBoardList/updateProjectPriority', async ({id, data}) => {
    const response = await editProjectPriority(id, data)
    return response
})


const dataSlice = createSlice({
    name: 'scrumBoardList/data',
    initialState: {
        isLoading: false,
        isError: false,
        error: '',
        success: null,
        searchingParams: {},
        projectInfo: {},
        taskLists: {},
        taskListSections: {},
        addedListSections: {},
        columns: {},
        ordered: [],
        boardMembers: [],
        projectPriorities: [],
        isSuccessScrumBoardList: false,
        // allMembers: [],
    },
    reducers: {
        updateOrdered: (state, action) => {
            state.ordered = action.payload
        },
        updateColumns: (state, action) => {
            state.columns = action.payload
            // console.log(action.payload)
        },
        updateSearchingParams: (state, action) => {
            state.searchingParams = action.payload
            // console.log(action.payload)
        },
        updateTaskListSections: (state, action) => {
            state.taskListSections = action.payload
        },
        updateBoardMembers: (state, action) => {
            state.boardMembers = action.payload
        },
        updateSuccessScrumBoardListStatus: (state, action) => {
            state.isSuccessScrumBoardList = false
        },
        removeSuccessMessage: (state) => {
            state.success = null
        },
    },
    extraReducers: (builder)=> {
        builder
            .addCase(getBoardLists.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(getBoardLists.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.projectInfo = action.payload.data.projectInfo
                state.taskLists = action.payload.data.taskLists
                state.taskListSections = action.payload.data.taskListSectionsName
                state.ordered = action.payload.data.taskListSections
                state.columns = action.payload.data.tasks
                state.boardMembers = action.payload.data && action.payload.data.projectInfo && action.payload.data.projectInfo.members ? action.payload.data.projectInfo.members:[]
                state.projectPriorities = action.payload.data && action.payload.data.projectInfo && action.payload.data.projectInfo.projectPriorities ? action.payload.data.projectInfo.projectPriorities:[]
            })
            .addCase(getBoardLists.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(updateTaskOrdered.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(updateTaskOrdered.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                // state.columns = action.payload.data
                // state.boardMembers = action.payload.data && action.payload.data.members ? action.payload.data.members:[]
            })
            .addCase(updateTaskOrdered.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(createListSection.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(createListSection.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
               state.isSuccessScrumBoardList = true
            })
            .addCase(createListSection.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(updateListSection.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(updateListSection.fulfilled, (state, action) => {
                state.isLoading = false
                // state.isError = false
                // state.isSuccessScrumBoardList = true
                // console.log(action.payload.data)
                state.success = `${action.payload.data.name} Update Successfully`
            })
            .addCase(updateListSection.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(updateSectionTask.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(updateSectionTask.fulfilled, (state, action) => {
                state.isLoading = false
                // state.isError = false
                // state.isSuccessScrumBoardList = true
                // console.log(action.payload.data)
                state.success = `Update Successfully`
            })
            .addCase(updateSectionTask.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(createProjectPriority.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(createProjectPriority.fulfilled, (state, action) => {
                state.isLoading = false
                // state.isError = false
                // state.isSuccessScrumBoardList = true
                state.projectPriorities = action.payload.data
                console.log(action.payload.data)
                state.success = `Create Successfully`
            })
            .addCase(createProjectPriority.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(updateProjectPriority.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(updateProjectPriority.fulfilled, (state, action) => {
                state.isLoading = false
                // state.isError = false
                // state.isSuccessScrumBoardList = true
                state.projectPriorities = action.payload.data
                console.log(action.payload.data)
                state.success = `Update Successfully`
            })
            .addCase(updateProjectPriority.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
    },
})

export const {
    updateOrdered,
    updateColumns,
    updateSearchingParams,
    updateBoardMembers,
    updateSuccessScrumBoardListStatus,
    removeSuccessMessage
} =
    dataSlice.actions

export default dataSlice.reducer
