import {
    getAllMasterData,
    addMasterData,
    getMasterDataByFilterData, getMasterDataType, updateMasterData, getMasterData, inlineUpdateMasterData
} from 'services/MasterDataService'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// Initial State Define
const initialState = {
    allMasterData: [],
    masterData: {},
    masterDataSearchable :[],
    masterDataType :[],
    tableData: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        sort: {
            order: '',
            key: '',
        },
        query: {
            name: null,
            company: null,
        },
    },
    isLoading: false,
    isError: false,
    error: '',
    success: null,
}

// Fetch Master Data Thunk
export const fetchAllMasterData = createAsyncThunk(
    'masterData/fetchAllMasterData',
    async (data) => {
        return getAllMasterData(data)
    }
)
// Create Master Data Thunk
export const createMasterData = createAsyncThunk(
    'masterData/createMasterData',
    async (data) => {
        return addMasterData(data)
    }
)
// Create Master Data Thunk
export const editMasterData = createAsyncThunk(
    'masterData/editMasterData',
    async ({ id, data }) => {
        return updateMasterData(id, data)
    }
)
// Create Master Data Thunk
export const inlineEditMasterData = createAsyncThunk(
    'masterData/inLineEditMasterData',
    async ({ id, data }) => {
        return inlineUpdateMasterData(id, data)
    }
)
// Fetch Master Data Thunk
export const fetchSearchingMasterData = createAsyncThunk(
    'masterData/fetchSearchingMasterData',
    async (data) => {
        return getMasterDataByFilterData(data)
    }
)
// Fetch Master Data Thunk
export const fetchMasterData = createAsyncThunk(
    'masterData/fetchMasterData',
    async (id) => {
        return getMasterData(id)
    }
)

export const fetchMasterDataType = createAsyncThunk(
    'masterDataType/fetchMasterDataType',
    async ()=>{
        return getMasterDataType()
    }
)

const masterDataSlice = createSlice({
    name: 'masterDataSlice',
    initialState,
    reducers: {
        setAllMasterData: (state, action) => {
            state.allMasterData = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setEditableMasterData: (state, action) => {
            const editableMasterData = state.allMasterData.find(
                (masterData) => masterData.id === action.payload
            )

            state.masterData = editableMasterData
        },
        removeUserFromState: (state) => {
            state.masterData = {}
        },
        removeSuccessMessage: (state) => {
            state.success = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllMasterData.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchAllMasterData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.allMasterData = action.payload.data
                state.tableData.total = action.payload.total
            })
            .addCase(fetchAllMasterData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(createMasterData.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(createMasterData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.allMasterData.push(action.payload.data)
                state.masterData = action.payload.data
                state.success = `${action.payload.data.name} created successfully.`

            })
            .addCase(createMasterData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(editMasterData.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(editMasterData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                const indexToUpdate = state.allMasterData.findIndex(
                    (masterData) => masterData.id === action.payload.data.id
                )

                state.allMasterData[indexToUpdate] = action.payload.data
                state.masterData={}
            })
            .addCase(editMasterData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(inlineEditMasterData.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(inlineEditMasterData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
            })
            .addCase(inlineEditMasterData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(fetchMasterData.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchMasterData.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.masterData=action.payload.data
            })
            .addCase(fetchMasterData.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })

            .addCase(fetchSearchingMasterData.fulfilled,(state,action) => {
                state.isLoading = false
                state.isError = false
                state.masterDataSearchable = action.payload.data
            })

            .addCase(fetchMasterDataType.fulfilled, (state , action)=>{
                state.isLoading = false
                state.isError = false
                state.masterDataType = action.payload.data
            })

    },
})
export const {
    setAllMasterData,
    setTableData,
    removeUserFromState,
    removeSuccessMessage,
    setEditableMasterData
} = masterDataSlice.actions
export default masterDataSlice.reducer