import ApiService from "./ApiService";


// Get Master Data
export const getAllMasterData = async (data) => {
    try {
        const response = await ApiService.fetchData({
            url: '/master-data',
            method: 'get',
            params: data
        })

        return response.data;

    } catch (error) {
        return error.message;
    }
}

export const addMasterData = async (data) => {
    const response = await ApiService.fetchData({
        url: '/master-data',
        method: 'post',
        data,
    })

    return response.data;
}

export const updateMasterData = async (id, data) => {

    try {
        const response = await ApiService.fetchData({
            url: `/master-data/${id}`,
            method: 'put',
            data,
        })

        return response.data;

    } catch (error) {
        return error.message;
    }
}
export const inlineUpdateMasterData = async (id, data) => {

    try {
        const response = await ApiService.fetchData({
            url: `/master-data/inline-update/${id}`,
            method: 'put',
            data,
        })
console.log(response.data)
        return response.data;

    } catch (error) {
        return error.message;
    }
}

// Get Users
export const getMasterDataByFilterData = async (data) => {
    try {
        const response = await ApiService.fetchData({
            url: '/master-data/filtering',
            method: 'get',
            params: data,
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}

export const getMasterData = async (id) => {
    try {

        const response = await ApiService.fetchData({
            url: `/master-data/${id}`,
            method: 'get',
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}

// Get Master Data
export const getMasterDataType = async () => {
    try {
        const response = await ApiService.fetchData({
            url: '/master-data-type',
            method: 'get',
        })

        return response.data;

    } catch (error) {
        return error.message;
    }
}
