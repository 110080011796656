import appConfig from 'configs/app.config'
import { PERSIST_STORE_NAME } from 'constants/app.constant'

import deepParseJson from 'utils/deepParseJson'

export const AuthenticatedFetch = async (url, method, data, contentType) => {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    const persistData = deepParseJson(rawPersistData)
    const accessToken = persistData.auth.session.token

    const response = await fetch(`${appConfig.liveApiUrl}${url}`, {
        method: method,
        headers: {
            Accept: `application/json`,
            'Content-Type': contentType ? contentType : `application/json`,
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data) || null,
    })
    return response.json()
}
export const UnauthenticatedFetch = async (url, method, data, contentType) => {
    const response = await fetch(`${appConfig.liveApiUrl}${url}`, {
        method: method,
        headers: {
            Accept: `application/json`,
            'Content-Type': contentType ? contentType : `application/json`,
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(data) || null,
    })
    return response.json()
}
