import company from './companySlice'
import data from './masterDataSlice'
import { combineReducers } from 'redux'
import project from "./projectSlice";

const reducer = combineReducers({
    company,
    project,
    data,
})
export default reducer
