import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    addProject,
    getAllProjects,
    getProject, getProjectMembers, removeProject,
    restoreFromDeleteProject,
    updateProject
} from "../../../services/ProjectService";

export const fetchAllProjects = createAsyncThunk(
    'projects/fetchAllProject',
    async (data) => {
        return  getAllProjects(data)
    }
)

export const createProject = createAsyncThunk('projects/createProject', async (data) => {
    return  addProject(data)
})


// Fetch Single User
export const fetchProject = createAsyncThunk('projects/fetchProject', async (id) => {
    return getProject(id)
})


//Edit User Thunk
export const editProject = createAsyncThunk(
    'projects/editProject',
    async ({ id, data }) => {
        return updateProject(id, data)
    }
)

// Delete Company thunk
export const deleteProject = createAsyncThunk('projects/deleteProject', async (id) => {
    return removeProject(id)
})

// Restore Company thunk
export const restoreProject = createAsyncThunk('projects/restoreProject', async (id) => {
    return restoreFromDeleteProject(id)
})

export const fetchProjectMembers = createAsyncThunk('projects/projectMembers', async (id) => {
    return getProjectMembers(id)
})

const initialState = {
    projects: [],
    project:{},
    projectMembers:[],
    tableData: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        sort: {
            order: '',
            key: '',
        },
        query: {
            name: null,
            company: null,
        },
    },
    isLoading: false,
    isError: false,
    error: '',
    success: null,
}

const projectSlice = createSlice({
    name: 'projectSlice',
    initialState,
    reducers: {
        setAllProjectData: (state, action) => {
            state.projects = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setEditableProject: (state, action) => {
            state.project = state.projects.find(
                (masterData) => masterData.id === action.payload
            )
        },
        removeProjectFromState: (state) => {
            state.project = {}
        },
        removeSuccessMessage: (state) => {
            state.success = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllProjects.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchAllProjects.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.projects = action.payload.data
                state.tableData.total = action.payload.total
            })
            .addCase(fetchAllProjects.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(createProject.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(createProject.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.projects.push(action.payload.data)
                state.success = `${action.payload.data.name} Created Successfully`
            })
            .addCase(createProject.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(fetchProject.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchProject.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.project = action.payload.data
            })
            .addCase(fetchProject.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(deleteProject.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                const indexToUpdate = state.projects.findIndex(
                    (project) => project.id === action.payload.data.id
                )
                state.projects[indexToUpdate] = action.payload.data
                state.success = `${action.payload.data.name} Deleted Successfully`
            })
            .addCase(deleteProject.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(restoreProject.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(restoreProject.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                const indexToUpdate = state.projects.findIndex(
                    (project) => project.id === action.payload.data.id
                )

                state.projects[indexToUpdate] = action.payload.data
                state.success = `${action.payload.data.name} Restore Successfully`
            })
            .addCase(restoreProject.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(editProject.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(editProject.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                const indexToUpdate = state.projects.findIndex(
                    (project) => project.id === action.payload.data.id
                )

                state.projects[indexToUpdate] = action.payload.data
                state.project={}
                state.success = `${action.payload.data.name} Update Successfully`
            })
            .addCase(editProject.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(fetchProjectMembers.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchProjectMembers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.projectMembers = action.payload.data
            })
            .addCase(fetchProjectMembers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
    },
})
export const {
    setAllProjectData,
    setTableData,
    setEditableProject,
    removeProjectFromState,
    removeSuccessMessage
} = projectSlice.actions
export default projectSlice.reducer
