import {
    addWorkspace,
    addWorkspaceMember,
    editWorkspaceDescription,
    editWorkspaceName,
    getWorkspaces,
    removeWorkspace,
} from 'services/WorkspaceService'

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit')

// Fetch Workspace Async Thunk
export const fetchWorkspaces = createAsyncThunk(
    'workspaces/fetchWorkspaces',
    async () => {
        const workspaces = getWorkspaces()
        return workspaces
    }
)

// Create Workspace Async Thunk
export const createWorkspace = createAsyncThunk(
    'workspaces/createWorkspace',
    async (data) => {
        const workspace = addWorkspace(data)
        return workspace
    }
)

// Add New Member To Workspace  Async Thunk
export const addNewWorkspaceMember = createAsyncThunk(
    'workspaces/addNewWorkspaceMember',
    async ({ id, data }) => {
        const workspace = addWorkspaceMember(id, data)
        return workspace
    }
)

// Update Workspace Name
export const updateWorkspaceName = createAsyncThunk(
    'workspaces/updateWorkspaceTitle',
    async ({ id, data }) => {
        const workspace = editWorkspaceName(id, data)
        return workspace
    }
)

// Update Workspace Descrption
export const updateWorkspaceDescription = createAsyncThunk(
    'workspaces/updateWorkspaceDescription',
    async ({ id, data }) => {
        const workspace = editWorkspaceDescription(id, data)
        return workspace
    }
)

// Delete Workspace

export const deleteWorkspace = createAsyncThunk(
    'workspaces/deleteWorkspace',
    async (id) => {
        const workspace = removeWorkspace(id)
        return workspace
    }
)
// Initialize State

const initialState = {
    workspaces: [],
    workspace: {},
    success: null,
    isLoading: false,
    isError: false,
    error: '',
    addWorkspaceModal: false,
    editWorkspaceModal: false,
    viewWorkspace: false,
    viewWorkspaceDrawer: false,
}

const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        AddWorkspace: (state, action) => {
            state.addWorkspaceModal = action.payload
        },
        EditWorkspace: (state, action) => {
            state.editWorkspaceModal = action.payload
        },
        ViewWorkspace: (state, action) => {
            state.viewWorkspace = action.payload
        },
        ViewWorkspaceDrawer: (state, action) => {
            state.viewWorkspaceDrawer = action.payload
        },
        removeErrorMessage: (state) => {
            state.error = ''
        },
        removeSuccessMessage: (state) => {
            state.success = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkspaces.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchWorkspaces.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.workspaces = action.payload
            })
            .addCase(fetchWorkspaces.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
                state.workspaces = []
            })
            .addCase(createWorkspace.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(createWorkspace.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.workspaces.push(action.payload)
            })
            .addCase(createWorkspace.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(addNewWorkspaceMember.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(addNewWorkspaceMember.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                const indexToUpdate = state.workspaces.findIndex(
                    (workspace) => workspace.id === action.payload.id
                )

                state.workspaces[indexToUpdate] = action.payload
            })
            .addCase(addNewWorkspaceMember.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(updateWorkspaceName.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(updateWorkspaceName.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                const indexToUpdate = state.workspaces.findIndex(
                    (workspace) => workspace.id === action.payload.id
                )

                state.workspaces[indexToUpdate] = action.payload
            })
            .addCase(updateWorkspaceName.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(updateWorkspaceDescription.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(updateWorkspaceDescription.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                const indexToUpdate = state.workspaces.findIndex(
                    (workspace) => workspace.id === action.payload.id
                )

                state.workspaces[indexToUpdate] = action.payload
            })
            .addCase(updateWorkspaceDescription.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(deleteWorkspace.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(deleteWorkspace.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.success = 'Workspace Delete Successfully'
                state.workspaces = state.workspaces.filter(
                    (workspace) => workspace.id !== action.meta.arg
                )
            })
            .addCase(deleteWorkspace.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
    },
})
export const {
    AddWorkspace,
    EditWorkspace,
    ViewWorkspace,
    ViewWorkspaceDrawer,
    removeErrorMessage,
    removeSuccessMessage
} =
    workspaceSlice.actions
export default workspaceSlice.reducer
