import ApiService from './ApiService'
import appConfig from "../configs/app.config";
import axios from "axios";

export async function apiSignIn(data) {
    /*console.log(data)
    const user = {
        data: {
            token: 'wVYrxaeNa9OxdnULvde1Au5m5w63',
            user: {
                avatar: '/img/avatars/thumb-1.jpg',
                userName: 'Carolyn Perkins',
                email: 'carolyn.p@elstar.com',
                authority: ['admin', 'user', 'author'],
            },
        },
    }
    console.log(user)
    return user*/
    const response = await fetch(`${appConfig.liveApiUrl}/login`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    }).then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.error(error);
        });

    return response;

}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {

    try {
        const response = await axios.get(`${appConfig.liveApiUrl}/logout`, {
            method: 'get',
            headers: {
                "Content-Type": `application/json`,
            },
            body: JSON.stringify(data),
        }).then(function (response) {
            console.log(response)
            return response.data;
        }).catch(function (errors){

            return errors.message;
        });
        console.log(response)
        return response;
    } catch (error) {

        return error.message;
    }
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
