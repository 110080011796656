import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    addComment,
    addMoreMember,
    addMoreTag,
    addTodo,
    changeTodoStatus,
    getTodo,
    getTodos,
    updateDescription,
    updateTitle,
} from 'services/BoardService'

const initialState = {
    todos: [],
    todo: {},
    dialogView: false,
    isLoading: false,
    isError: false,
    error: '',
}

// async thunks

// Fetch All todos thunk
export const fetchTodos = createAsyncThunk('todos/fetchTodos', async () => {
    const todos = await getTodos()
    return todos
})
// Fetch All todos thunk
export const fetchTodo = createAsyncThunk('todos/fetchTodo', async (id) => {
    const todo = await getTodo(id)
    return todo
})

// Create  todo thunk
export const createTodo = createAsyncThunk('todos/createTodo', async (data) => {
    const todo = await addTodo(data)
    return todo
})

// Add New Member todos thunk
export const editTitle = createAsyncThunk(
    'todos/editTitle',
    async ({ id, data }) => {
        const todo = await updateTitle(id, data)
        return todo
    }
)

// Add New Member todos thunk
export const editDesciption = createAsyncThunk(
    'todos/editDescription',
    async ({ id, data }) => {
        const todo = await updateDescription(id, data)
        return todo
    }
)

// Add New Member todos thunk
export const addNewMember = createAsyncThunk(
    'todos/addNewMember',
    async ({ id, data }) => {
        const todo = await addMoreMember(id, data)
        return todo
    }
)

// Add New Tag todos thunk
export const addNewTag = createAsyncThunk(
    'todos/addNewTag',
    async ({ id, data }) => {
        const todo = await addMoreTag(id, data)
        return todo
    }
)

// Add New Comment todos thunk
export const addNewComment = createAsyncThunk(
    'todos/addNewComment',
    async ({ id, data }) => {
        const todo = await addComment(id, data)
        return todo
    }
)

// Change todos status  thunk
export const changeStatus = createAsyncThunk(
    'todos/changeStatus',
    async ({ id, data }) => {
        const todo = await changeTodoStatus(id, data)
        return todo
    }
)

const todoSlice = createSlice({
    name: 'todos',
    initialState,
    reducers: {
        openDialog: (state) => {
            state.dialogView = true
        },
        closeDialog: (state) => {
            state.dialogView = false
        },
        updateTodo: (state, action) => {
            state.todo = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTodos.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(fetchTodos.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.todos = action.payload
            })
            .addCase(fetchTodos.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
                state.todos = []
            })
            .addCase(fetchTodo.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(fetchTodo.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.todo = action.payload
            })
            .addCase(fetchTodo.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
                state.todo = {}
            })
            .addCase(createTodo.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(createTodo.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.todos.push(action.payload)
            })
            .addCase(createTodo.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(changeStatus.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(changeStatus.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                const indexToUpdate = state.todos.findIndex(
                    (todo) => todo.id === action.payload.id
                )

                state.todos[indexToUpdate] = action.payload
            })
            .addCase(changeStatus.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(editTitle.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(editTitle.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                const indexToUpdate = state.todos.findIndex(
                    (todo) => todo.id === action.payload.id
                )

                state.todos[indexToUpdate] = action.payload
            })
            .addCase(editTitle.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(editDesciption.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(editDesciption.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                const indexToUpdate = state.todos.findIndex(
                    (todo) => todo.id === action.payload.id
                )

                state.todos[indexToUpdate] = action.payload
            })
            .addCase(editDesciption.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(addNewMember.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(addNewMember.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                const indexToUpdate = state.todos.findIndex(
                    (todo) => todo.id === action.payload.id
                )

                state.todos[indexToUpdate] = action.payload
            })
            .addCase(addNewMember.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(addNewComment.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(addNewComment.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                const indexToUpdate = state.todos.findIndex(
                    (todo) => todo.id === action.payload.id
                )

                state.todos[indexToUpdate] = action.payload
            })
            .addCase(addNewComment.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(addNewTag.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(addNewTag.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                const indexToUpdate = state.todos.findIndex(
                    (todo) => todo.id === action.payload.id
                )

                state.todos[indexToUpdate] = action.payload
            })
            .addCase(addNewTag.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
    },
})
export const { openDialog, closeDialog, updateTodo } = todoSlice.actions

export default todoSlice.reducer
