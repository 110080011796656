import ApiService from './ApiService'

export async function getAllProjects(data) {
    try {
        const response = await ApiService.fetchData({
            url: '/projects',
            method: 'get',
            params: data
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}
export async function addProject(data) {
    try {
        const response = await ApiService.fetchData({
            url: '/projects',
            method: 'post',
            data,
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}

export const getProject = async (id) => {
    try {

        const response = await ApiService.fetchData({
            url: `/projects/${id}`,
            method: 'get',
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}
export const updateProject = async (id, data) => {
    try {
        const response = await ApiService.fetchData({
            url: `/projects/${id}`,
            method: 'put',
            data
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}


export const removeProject = async (id) => {
    try {
        const response = await ApiService.fetchData({
            url: `/projects/${id}`,
            method: 'delete',
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}

export const restoreFromDeleteProject = async (id) => {
    try {
        const response = await ApiService.fetchData({
            url: `/projects/restore/${id}`,
            method: 'get',
        })
        return response.data;

    } catch (error) {
        return error.message;
    }

}

export const getProjectMembers = async (id) => {
    try {
        const response = await ApiService.fetchData({
            url: `/projects/members/${id}`,
            method: 'get',
        })
        return response.data;

    } catch (error) {
        return error.message;
    }

}
export async function apiGetProjectDashboardData(params) {
    return ApiService.fetchData({
        url: '/project/dashboard',
        method: 'get',
        params,
    })
}

export async function apiGetProjectList(data) {
    return ApiService.fetchData({
        url: '/project/list',
        method: 'post',
        data,
    })
}

export async function apiPutProjectList(data) {
    return ApiService.fetchData({
        url: '/project/list/add',
        method: 'put',
        data,
    })
}

export async function apiGetScrumBoards() {
    return ApiService.fetchData({
        url: '/project/scrum-board/boards',
        method: 'post',
    })
}


export async function apiGetScrumBoardLists(data) {
    try {
        const response = await ApiService.fetchData({
            url: '/projects/scrum-board-list/by/project',
            method: 'get',
            params: data
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}


export async function updateProjectTaskOrder(data) {
    try {
        const response = await ApiService.fetchData({
            url: '/projects/scrum-board-list-and-task/sort-order/update',
            method: 'post',
            data,
        })
        console.log(response.data)
        return response.data;

    } catch (error) {
        return error.message;
    }
}

export async function apiGetScrumBoardtMembers() {
    return ApiService.fetchData({
        url: '/project/scrum-board/members',
        method: 'post',
    })
}

export async function apiGetScrumBoardtTicketDetail(params) {
    return ApiService.fetchData({
        url: '/project/scrum-board/tickets/detail',
        method: 'get',
        params,
    })
}
