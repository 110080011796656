import {
    addUser,
    getUser,
    getUsers,
    updateUser,
    removeUser, getUserByFilterData,
} from 'services/UserService'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// Initial State Define
const initialState = {
    users: [],
    members: [],
    usersSearchable :[],
    user: {},
    tableData: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        sort: {
            order: '',
            key: '',
        },
        query: {
            name: null,
            company: null,
        },
    },
    isLoading: false,
    isError: false,
    error: '',
    success: null,
    viewEditModal: false,
}

// Fetch User Thunk
export const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async (data) => {
        const users = getUsers(data)
        return users
    }
)

// Fetch Searching User Thunk
export const fetchSearchingUsers = createAsyncThunk(
    'users/fetchSearchingUsers',
    async (data) => {
        return  getUserByFilterData(data)
    }
)



// Fetch Single User
export const fetchUser = createAsyncThunk('users/fetchUser', async (id) => {
    const user = getUser(id)
    return user
})

// Create User Thunk
export const createUser = createAsyncThunk('users/createUser', async (data) => {
    const user = addUser(data)
    return user
})

//Edit User Thunk
export const editUser = createAsyncThunk(
    'users/editUser',
    async ({ id, data }) => {
        const user = updateUser(id, data)
        return user
    }
)
// Delete User thunk
export const deleteUser = createAsyncThunk('users/deleteUser', async (id) => {
    const user = removeUser(id)
    return user
})
const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload
            state.members = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setEditableUser: (state, action) => {
            const editableUser = state.users.find(
                (user) => user.id === action.payload
            )

            state.user = editableUser
        },
        removeUserFromState: (state) => {
            state.user = {}
        },
        removeSuccessMessage: (state) => {
            state.success = null
        },
        updateViewEditModal: (state, action) => {
            state.viewEditModal = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.users = action.payload.data
                state.members = action.payload.data
                state.tableData.total = action.payload.total
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(fetchUser.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.user = action.payload
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(createUser.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.users.push(action.payload.data)
                state.members.push(action.payload.data)
                state.user = action.payload.data
                if(action.payload.data.status === 403){
                    state.error = action.payload.data.message
                }else {
                    state.success = `${action.payload.data.name} Created Successfully`
                }
            })
            .addCase(createUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
                console.log('rejected')
            })
            .addCase(editUser.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(editUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                const indexToUpdate = state.users.findIndex(
                    (user) => user.id === action.payload.data.id
                )

                state.users[indexToUpdate] = action.payload.data
                state.members[indexToUpdate] = action.payload.data
                state.success = `${action.payload.data.name} Updated Successfully`
            })
            .addCase(editUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(deleteUser.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false

                state.users = state.users.filter(
                    (user) => user.id !== action.meta.arg
                )
                state.members = state.members.filter(
                    (user) => user.id !== action.meta.arg
                )
                state.success = `User Deleted Successfully`
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(fetchSearchingUsers.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(fetchSearchingUsers.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.usersSearchable = action.payload.data
            })
            .addCase(fetchSearchingUsers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
    },
})
export const {
    setUsers,
    setTableData,
    removeUserFromState,
    removeSuccessMessage,
    setEditableUser,
    updateViewEditModal,
} = userSlice.actions
export default userSlice.reducer