import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    addTask, addTaskAttachment,
    addTaskComment,
    getAllProjectTasks,
    getAllTasks,
    getTask, removeTaskAttachment,
    updateTask
} from "../../../../services/ProjectTasksService";

export const fetchAllTasks = createAsyncThunk(
    'projectTasks/fetchAllTask',
    async (data) => {
        return  getAllTasks(data)
    }
)

export const fetchAllProjectTasks = createAsyncThunk(
    'projectTasks/fetchAllProjectTasks',
    async (data) => {
        return  getAllProjectTasks(data)
    }
)

export const createTask = createAsyncThunk('projectTasks/createTask', async ({data}) => {
    return  addTask(data)
})

export const editTask = createAsyncThunk('projectTasks/editTask', async ({ id, data }) => {
    return  updateTask(id, data)
})


// Fetch Single User
export const fetchTask = createAsyncThunk('projectTasks/fetchTask', async (id) => {
    return getTask(id)
})

// Fetch Single User
export const createTaskAttachment = createAsyncThunk('projectTasks/createTaskAttachment', async ({data}) => {
    return addTaskAttachment(data)
})

// Fetch Single User
export const deleteTaskAttachment = createAsyncThunk('projectTasks/deleteTaskAttachment', async ({id}) => {
    return removeTaskAttachment(id)
})

export const createTaskComment = createAsyncThunk('projectTasks/createTaskComment', async ({ id, data }) => {
    return  addTaskComment(id, data)
})


const initialState = {
    tasks: [],
    projectTasks: [],
    task:{},
    taskComments: [],
    taskId:null,
    tableData: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        sort: {
            order: '',
            key: '',
        },
        query: {
            name: null,
            company: null,
        },
    },
    isLoading: false,
    isError: false,
    isSuccess: false,
    error: '',
    success: null,
}

const projectTaskSlice = createSlice({
    name: 'projectTaskSlice',
    initialState,
    reducers: {
        setAllTasksData: (state, action) => {
            state.tasks = action.payload
        },
        setAllProjectTasksData: (state, action) => {
            state.projectTasks = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        updateTaskObject: (state, action) => {
            state.task = {}
        },
        setTaskId: (state, action) => {
            state.taskId = action.payload
        },
        removeSuccessMessage: (state) => {
            state.success = null
        },
        updateSuccessStatus: (state) => {
            state.isSuccess = false
        },
        updateTaskComments: (state) => {
            state.taskComments = []
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTasks.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchAllTasks.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.tasks = action.payload.data
                state.tableData.total = action.payload.total
            })
            .addCase(fetchAllTasks.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(fetchAllProjectTasks.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchAllProjectTasks.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.projectTasks = action.payload.data
                state.tableData.total = action.payload.total
            })
            .addCase(fetchAllProjectTasks.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(createTask.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(createTask.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                console.log(action.payload)
                if(action.payload.data && action.payload.data.parent_id===null){
                    state.tasks.push(action.payload.data)
                    state.projectTasks.push(action.payload.data)
                }

                // console.log(action.payload.requestData)
                state.success = `${action.payload.data.name} Created Successfully`
                state.isSuccess = true
            })
            .addCase(createTask.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(editTask.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(editTask.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                const indexToUpdate = state.tasks.findIndex(
                    (task) => task.id === action.payload.data.id
                )
                state.tasks[indexToUpdate] = action.payload.data
                const indexToUpdateProjectTask = state.projectTasks.findIndex(
                    (task) => task.id === action.payload.data.id
                )
                state.projectTasks[indexToUpdateProjectTask] = action.payload.data
                state.success = `${action.payload.data.name} Update Successfully`
                state.isSuccess = true
            })
            .addCase(editTask.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(fetchTask.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchTask.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.task = action.payload.data
            })
            .addCase(fetchTask.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(createTaskAttachment.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(createTaskAttachment.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.task = action.payload.data
                state.success = `Attachment uploaded Successfully`
                state.isSuccess = true
            })
            .addCase(createTaskAttachment.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(deleteTaskAttachment.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(deleteTaskAttachment.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.task = action.payload.data
            })
            .addCase(deleteTaskAttachment.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(createTaskComment.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(createTaskComment.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.taskComments = action.payload.data
                console.log(action.payload.data)
            })
            .addCase(createTaskComment.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
    },
})
export const {
    setAllTasksData,
    setAllProjectTasksData,
    setTableData,
    removeSuccessMessage,
    updateSuccessStatus,
    updateTaskComments,
    setTaskId,
    updateTaskObject
} = projectTaskSlice.actions
export default projectTaskSlice.reducer
