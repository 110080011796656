import { getCompanies } from 'services/UserService'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const fetchCompanies = createAsyncThunk(
    '/companies/fetchCompany',
    async () => {
        const companies = getCompanies()
        return companies
    }
)
const initialState = {
    companies: [],
    isLoading: false,
    isError: false,
    error: '',
}

const companySlice = createSlice({
    name: 'company',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanies.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchCompanies.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.companies = action.payload
            })
            .addCase(fetchCompanies.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
    },
})

export default companySlice.reducer
