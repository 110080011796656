import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getMembers } from 'services/BoardService'

// initial State
const initialState = {
    members: [],
    isLoading: false,
    isError: false,
    error: '',
}

// async thunks
export const fetchMembers = createAsyncThunk(
    'members/fetchMembers',
    async () => {
        const members = await getMembers()
        return members
    }
)

const memberSlice = createSlice({
    name: 'members',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchMembers.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(fetchMembers.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.members = action.payload
            })
            .addCase(fetchMembers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
                state.members = []
            })
    },
})
export default memberSlice.reducer
