import appConfig from 'configs/app.config'
import { AuthenticatedFetch, UnauthenticatedFetch } from 'utils/BaseFetcher'
import ApiService from "./ApiService";

export const getWorkspaceMembers = async (id) => {
    const response = await AuthenticatedFetch(`/members`)
    return response
}

// Board API
export const getBoards = async () => {
    const response = await fetch(`${appConfig.liveApiUrl}/boards`)
    return response.json()
}
export const addBoard = async (data) => {
    const response = await fetch(`${appConfig.liveApiUrl}/boards`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    })

    return response.json()
}

export const updateBoard = async (id, data) => {
    const response = await fetch(`${appConfig.liveApiUrl}/sections/${id}`, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    })

    return response.json()
}
export const removeBoard = async (id) => {
    const response = await fetch(`${appConfig.liveApiUrl}/sections/${id}`, {
        method: 'DELETE',
    })
    return response
}

//  Member API
export const getMembers = async () => {
    try {
        const response = await ApiService.fetchData({
            url: '/users',
            method: 'get',
        })
        console.log(response.data)
        return response.data;

    } catch (error) {
        return error.message;
    }
}

// Board Member API
export const getBoardMembers = async () => {
    const response = await fetch(`${appConfig.liveApiUrl}/project-members`)

    return response.json()
}

// Section API
export const getSections = async () => {
    const response = AuthenticatedFetch('/sections', 'get')

    return response
}
export const addSection = async (data) => {
    const response = await fetch(`${appConfig.liveApiUrl}/sections`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    })

    return response.json()
}

export const updateSection = async (id, data) => {
    const response = await fetch(`${appConfig.liveApiUrl}/sections/${id}`, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    })

    return response.json()
}
export const removeSection = async (id) => {
    const response = await fetch(`${appConfig.liveApiUrl}/sections/${id}`, {
        method: 'DELETE',
    })
    return response
}

// Tags API
export const getTags = async () => {
    const response = await fetch(`${appConfig.liveApiUrl}/tags`)

    return response.json()
}
export const addTag = async (data) => {
    const response = await fetch(`${appConfig.liveApiUrl}/tags`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    })

    return response.json()
}

// Todos API
// Get All Todo
export const getTodos = async () => {
    const response = await fetch(`${appConfig.liveApiUrl}/todos`)
    return response.json()
}

// Get Single Todo

export const getTodo = async (id) => {
    const response = await fetch(`${appConfig.liveApiUrl}/todos/${id}`)
    return response.json()
}

// Add New Todo
export const addTodo = async (data) => {
    const response = await UnauthenticatedFetch(
        '/todos',
        'post',
        data,
        'multipart/form-data'
    )
    // const response = await fetch(`${appConfig.liveApiUrl}/todos`, {
    //     method: 'POST',
    //     headers: {
    //         'Content-type': 'application/json; charset=UTF-8',
    //     },
    //     body: JSON.stringify(data),
    // })

    return response
    // return response.json()
}

// Update Todo Title
export const updateTitle = async (id, data) => {
    const response = await fetch(`${appConfig.liveApiUrl}/todos/${id}`, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    })

    return response.json()
}

// Update Todo Description
export const updateDescription = async (id, data) => {
    const response = await fetch(`${appConfig.liveApiUrl}/todos/${id}`, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    })

    return response.json()
}

// Add New Member Into Todo
export const addMoreMember = async (id, data) => {
    const response = await fetch(`${appConfig.liveApiUrl}/todos/${id}`, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    })

    return response.json()
}

// Add New Tag Into Todo

export const addMoreTag = async (id, data) => {
    const response = await fetch(`${appConfig.liveApiUrl}/todos/${id}`, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    })

    return response.json()
}

// Add New Comment Into Todo

export const addComment = async (id, data) => {
    const response = await fetch(`${appConfig.liveApiUrl}/todos/${id}`, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    })

    return response.json()
}

// Change Todo Status
export const changeTodoStatus = async (id, data) => {
    const response = await fetch(`${appConfig.liveApiUrl}/todos/${id}`, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
    })

    return response.json()
}
