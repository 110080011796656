import appConfig from 'configs/app.config'
import ApiService from "./ApiService";

// Get Users
export const getUsers = async (data) => {
    try {
        const response = await ApiService.fetchData({
            url: '/users',
            method: 'get',
            params: data
        })
        console.log(response.data)
        return response.data;

    } catch (error) {
        return error.message;
    }
}

export const getUserByFilterData = async (data) => {
    try {
        const response = await ApiService.fetchData({
            url: '/users/filtering',
            method: 'get',
            params: data,
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}


// Get Single User
export const getUser = async (id) => {
    const response = await fetch(`${appConfig.liveApiUrl}/users/${id}`)
    return response.json()
}

// Add User

export const addUser = async (data) => {
    const response = await ApiService.fetchData({
        url: '/users',
        method: 'post',
        data,
    })

    return response.data;
}

// Update User

export const updateUser = async (id, data) => {

    const response = await ApiService.fetchData({
        url: `/users/${id}`,
        method: 'put',
        data,
    })

    return response.data;
}

// Remove User

export const removeUser = async (id) => {
    const response = await fetch(`${appConfig.liveApiUrl}/users/${id}`, {
        method: 'DELETE',
    })

    return response.json()
}
// Get Companies
export const getCompanies = async () => {
    const response = await fetch(`${appConfig.liveApiUrl}/companies`)

    return response.json()
}

// Get Roles
export const getRoles = async () => {
    const response = await ApiService.fetchData({
        url: '/roles',
        method: 'get',
    })

    return response.data;
}

// Add Role
export const addRole = async (data) => {
    const response = await ApiService.fetchData({
        url: '/roles',
        method: 'post',
        data,
    })

    return response.data;

}

// Update User

export const updateRole = async (id, data) => {

    const response = await ApiService.fetchData({
        url: `/roles/${id}`,
        method: 'put',
        data,
    })

    return response.data;
}

// Remove Role
export const removeRole = async (id) => {
    const response = await ApiService.fetchData({
        url: `/roles/${id}`,
        method: 'delete',
    })
    return response.data;
}
// Get Permissions
export const getPermissions = async () => {
    const response = await ApiService.fetchData({
        url: '/permissions',
        method: 'get',
    })
    return response.data;
}
