import ApiService from './ApiService'

export async function getAllTasks(data) {
    try {
        const response = await ApiService.fetchData({
            url: '/tasks',
            method: 'get',
            params: data
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}

export async function getAllProjectTasks(data) {
    try {
        const response = await ApiService.fetchData({
            url: '/tasks/by/project',
            method: 'get',
            params: data
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}
export async function addTask(data) {
    try {
        const response = await ApiService.fetchData({
            url: '/tasks',
            method: 'post',
            headers: {
                "Accept": "application/json, text/plain, */*",
                'Content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
            },
            data: data
        })

        return response.data;

    } catch (error) {
        return error.message;
    }
}


export const updateTask = async (id, data) => {
    try {
        const response = await ApiService.fetchData({
            url: `/tasks/${id}`,
            method: 'put',
            data
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}

export const getTask = async (id) => {
    try {

        const response = await ApiService.fetchData({
            url: `/tasks/${id}`,
            method: 'get',
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}

export const addTaskAttachment = async (data) => {
    try {
        const response = await ApiService.fetchData({
            url: `/tasks/attachment/create`,
            method: 'post',
            headers: {
                "Accept": "application/json, text/plain, */*",
                'Content-type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
            },
            data
        })

        return response.data;

    } catch (error) {
        return error.message;
    }
}

export const removeTaskAttachment = async (id) => {
    try {
        const response = await ApiService.fetchData({
            url: `/tasks/attachment/${id}/delete`,
            method: 'delete',
        })

        return response.data;

    } catch (error) {
        return error.message;
    }
}

export async function addListSection(data) {
    try {
        const response = await ApiService.fetchData({
            url: '/list/sections',
            method: 'post',
            data,
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}


export const editListSection = async (id, data) => {
    try {
        const response = await ApiService.fetchData({
            url: `/list/sections/${id}`,
            method: 'put',
            data
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}

export const editSectionTask = async (id, data) => {
    try {
        const response = await ApiService.fetchData({
            url: `/section/tasks/${id}`,
            method: 'put',
            data
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}


export async function addProjectPriority(data) {
    try {
        const response = await ApiService.fetchData({
            url: '/priorities',
            method: 'post',
            data,
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}

export const editProjectPriority = async (id, data) => {
    try {
        const response = await ApiService.fetchData({
            url: `/priorities/${id}`,
            method: 'put',
            data
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}



export const addTaskComment = async (id, data) => {
    try {
        const response = await ApiService.fetchData({
            url: `/tasks/${id}/comment/create`,
            method: 'post',
            data
        })
        return response.data;

    } catch (error) {
        return error.message;
    }
}
