import company from './companySlice'
import role from './roleSlice'
import user from './userSlice'
import permission from './permissionSlice'
import { combineReducers } from 'redux'

const reducer = combineReducers({
    company,
    role,
    user,
    permission,
})
export default reducer
