import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getPermissions } from '../../../services/UserService'

// Initial State Define
const initialState = {
    permissions: [],
    isLoading: false,
    isError: false,
    error: '',
}

// Fetch User Thunk
export const fetchPermisions = createAsyncThunk(
    'permissions/fetchPermisions',
    async () => {
        const permissions = getPermissions()
        return permissions
    }
)

const permissionSlice = createSlice({
    name: 'permissions',
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(fetchPermisions.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchPermisions.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.permissions = action.payload?.data
            })
            .addCase(fetchPermisions.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
    },
})
export default permissionSlice.reducer
