import { addRole, getRoles, removeRole, updateRole } from 'services/UserService'

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// Fetch Role Thunk
export const fetchRoles = createAsyncThunk('roles/fetchRoles', async () => {
    const roles = getRoles()
    return roles
})

// Create Role Thunk
export const createRole = createAsyncThunk('roles/createRole', async (data) => {
    const role = addRole(data)
    return role
})

// Edit Role Thunk
export const editRole = createAsyncThunk(
    'roles/editRole',
    async ({ id, data }) => {
        const role = updateRole(id, data)
        return role
    }
)

// Delete Role Thunk

export const deleteRole = createAsyncThunk('roles/deleteRole', async (id) => {
    console.log(id)
    const role = removeRole(id)
    return role
})

// State Initialize
const initialState = {
    roles: [],
    isLoading: false,
    isError: false,
    error: '',
    success: null,
}

const roleSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        removeSuccessMessage: (state) => {
            state.success = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoles.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.roles = action.payload.data
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(createRole.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(createRole.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.roles.push(action.payload.data)
                state.success = `${action.payload.data?.name} Created Successfully`
            })
            .addCase(createRole.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(editRole.pending, (state) => {
                state.isLoading = true
                state.isError = false
            })
            .addCase(editRole.fulfilled, (state, action) => {
                state.isLoading = false
                state.isError = false
                const indexToUpdate = state.roles.findIndex(
                    (role) => role.id === action.payload.data.id
                )

                state.roles[indexToUpdate] = action.payload.data
                state.success = `${action.payload.data?.name} Updated Successfully`
            })
            .addCase(editRole.rejected, (state, action) => {
                state.isLoading = false
                state.isError = false
                state.error = action.error?.message
            })
            .addCase(deleteRole.pending, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(deleteRole.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false

                state.roles = state.roles.filter(
                    (role) => role.id !== action.meta.arg
                )
                state.success = `Role Deleted Successfully`
            })
            .addCase(deleteRole.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
    },
})

export const { removeSuccessMessage } = roleSlice.actions
export default roleSlice.reducer
