import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    addSection,
    getSections,
    removeSection,
    updateSection,
} from 'services/BoardService'

const initialState = {
    sections: [],
    isLoading: false,
    isError: false,
    error: '',
    editing: {},
}

// async thunks

export const fetchSections = createAsyncThunk(
    'sections/fetchSections',
    async () => {
        const sections = await getSections()
        return sections
    }
)
export const createSections = createAsyncThunk(
    'sections/createSection',
    async (data) => {
        const section = await addSection(data)
        return section
    }
)
export const editSection = createAsyncThunk(
    'sections/editSection',
    async ({ id, data }) => {
        const section = await updateSection(id, data)
        return section
    }
)
export const deleteSection = createAsyncThunk(
    'sections/deleteSection',
    async (id) => {
        const section = await removeSection(id)
        return section
    }
)
const sectionSlice = createSlice({
    name: 'sections',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchSections.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(fetchSections.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.sections = action.payload
            })
            .addCase(fetchSections.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
                state.sections = []
            })
            .addCase(createSections.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(createSections.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.sections.push(action.payload)
            })
            .addCase(createSections.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(editSection.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(editSection.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                const indexToUpdate = state.sections.findIndex(
                    (section) => section.id === action.payload.id
                )

                state.sections[indexToUpdate] = action.payload
            })
            .addCase(editSection.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
            .addCase(deleteSection.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(deleteSection.fulfilled, (state, action) => {
                console.log(action)
                state.isError = false
                state.isLoading = false

                state.sections = state.sections.filter(
                    (section) => section.id !== action.meta.arg
                )
            })
            .addCase(deleteSection.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
            })
    },
})
export default sectionSlice.reducer
