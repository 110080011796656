import todo from './todoSlice'
import section from './sectionSlice'
import board from './boardSlice'
import member from './memberSlice'
import tag from './tagSlice'
import boardMember from './boardMemberSlice'
import { combineReducers } from 'redux'
const reducer = combineReducers({
    todo,
    member,
    tag,
    section,
    board,
    boardMember,
})
export default reducer
