import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getBoardMembers } from 'services/BoardService'

// initial State
const initialState = {
    boardMembers: [],
    isLoading: false,
    isError: false,
    error: '',
}

// async thunks
export const fetchBoardMembers = createAsyncThunk(
    'boardMembers/fetchBoardMembers',
    async () => {
        const boardMembers = await getBoardMembers()
        return boardMembers
    }
)

const boardMemberSlice = createSlice({
    name: 'boardMembers',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchBoardMembers.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(fetchBoardMembers.fulfilled, (state, action) => {
                state.isError = false
                state.isLoading = false
                state.boardMembers = action.payload
            })
            .addCase(fetchBoardMembers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.error = action.error?.message
                state.boardMembers = []
            })
    },
})
export default boardMemberSlice.reducer
